import React from "react";
import { SEO, MarkdownContent, Accordion } from "@bluefin/components";
import { Grid, Segment, Icon } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class PoliciesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.hairitisri.com/policies/"}
        />
        <Grid
          className={"custom-page-policies custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"fdyotczurawsyexo"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"ggdouuyinodvgsbj"}
              style={{ padding: 16 }}
              width={10}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "c5fba72b-215c-4758-b8e3-77c30dfd70d7",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"nxygfzndrexzsuiq"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"vrvpggqffknmzyjy"}
              style={{ padding: 16, background: "#FEF3DC" }}
              width={10}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Accordion
                  className={""}
                  style={{}}
                  styled={true}
                  exclusive={true}
                  defaultActiveIndexes={[]}
                >
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "a2182cc4-466a-400e-8d70-6e8c12c2a9a3",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "6562077d-1db8-492c-9a22-fc2715011ae8",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "c949765a-34c4-46e1-8f42-e5cde46fceda",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "50d1a676-7ac9-4eee-b805-42fcaa4e6a33",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "fb2d7215-0c7c-4180-9491-e55fd6b0a47c",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "0fe31b2a-cc51-413e-a201-6520bc701222",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 28416 }) {
      title
      seoTitle
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
